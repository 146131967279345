<template>
  <el-main class="simple4-container">
    <section class="inner-container">
      <div class="section-h2">
        <MainContain />
        沒事多騎車
      </div>
      <p>2023年2月 </p>
      <div class="image-container">
        <img :src="image" class="image" alt="秋冬單車｜開啟新路線">
        <p class="image_desc">秋冬單車｜開啟新路線</p>
        <p class="image_desc">圖片為沒事多騎車活動主視覺照片</p>
        <p class="image_desc">(圖片提供：沛司科技)</p>
      </div>
      <div class="content-section">
        <div class="section-h3">公益目標</div>
        <div class="content-desc">
          根據110年教育部體育署針對台灣運動現況的調查結果顯示：騎車運動在運動項目中佔男性11.5%，女性7.2%，成果顯示：在運動項目當中騎車運動還有很大的推廣空間，希望透過該活動促成台灣騎乘單車運動，形成節能減碳風氣，後續可推動更多創新與數據利他價值應用。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據貢獻活動 </div>
        <div class="content-desc">
          本計畫協手沛司科技、中華民國自行車協會一同辦理「沒事多騎車：秋冬激勵騎車計畫」，激勵民眾單車騎乘，每個月根據季節特色提供精美小物禮品，以單次3公里為基準，騎乘里程根據需求和喜好兌換不同的獎品。
        </div>
        <br>
        <div class="content-desc">
          於民國111年10月26日至112年1月25日期間，共完成累計1,006人報名及74,256筆運動生理數據，總累積里程426,751公里，數據包含經緯度、速度、距離、總上升海拔、總下降海拔、最大速度與迴轉數等運動數據，完整數據留存原始資料於Velodash系統中，並已與運動數據公益平台串接。 
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據公益模式</div>
        <div class="content-desc">
          收集了全台各年齡層戶外騎車的各項統計數字，可以利用此數據分析用戶各項戶外數據型態，協助分析各路線的難易度與騎乘表現，也可找出熱門路線，用來規劃自行車用品店展店位置或協助開發自行車相關產品。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">公益展望</div>
        <div class="content-desc">
          單車活動中收集的數據：如軌跡、用路時段、區域熱點、年齡輪廓等，有助於後續在單車建設方面的參考和改善。
        </div>
      </div>
    </section>
  </el-main>
</template>

<script>
import MainContain from '@/components/Accessibility/MainContain.vue'

export default {
  name: 'UseCase11104',
  components: {
    MainContain
  },
  data () {
    return {
      image: require('@/assets/images/sample/sample_4.png')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.simple4-container{
  .inner-container{
    max-width: 800px;
    margin: 0 auto;
    padding: 1.25rem 0;
  }
  .image-container{
    padding: 1.5rem 0;
    .image {
      width: 80%;
      display: block;
      margin: 0 auto;
      }
    .image_desc{
      text-align: center;
    }
  }
  
  .content-section{
    
    .content-desc{
      line-height: 1.6;
    }
  }
}

</style>
